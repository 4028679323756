@import url(https://fonts.googleapis.com/css2?family=Langar&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@300;500&family=Yanone+Kaffeesatz:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=BioRhyme:wght@300;400;700&family=Cabin:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=BioRhyme:wght@300;400;700&family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Roboto */
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'); */

/* Matrial icons */
/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */

/* Logo name */
html {
  scroll-behavior: smooth;
}

body {
  background-color: #FCF6ED;
  font-family: 'Crimson Text', serif;
}

.App {
  text-align: center;
}

.website-container { 
  max-width: 60%;
  margin: auto;
}

/* Common classes begin */

.document, iframe {
  margin: 0 auto;
  background-color: #FCF6ED;
}

iframe {
  display: block;
  border-style:none;
}

.section-header { 
  font-family: 'Alegreya', serif;
}

hr { 
  border: 2px dashed #000000;
  border-style: none none dashed; 
}

.nav-divider { 
  margin-top: -20px;
}

.MuiButton-label { 
  font-family: 'Open Sans', sans-serif;
  color: #A14112;
  font-weight: bold;
}


/* Common classes end */

/* Navbar stuff begins */

.name-logo {
  color: #A14112;
  text-decoration: none;
  font-size: 1.4em;
}

.navbar-container { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navmenu-container { 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navmenu { 
  list-style-type: none;
  overflow: hidden;
}

.navitem { 
  display:inline; 
  margin: 1em;
  font-size: 18px;
}

.navitem a:link { 
  color: #A14112;
  text-decoration: none;
}
.navitem a:visited { 
  color: #A14112;
  text-decoration: none;
}
.navitem a:hover { 
  color: black;
  text-decoration: underline;
}
.navitem a:active { 
  color: black;
  text-decoration: underline;
}

/* Navbar stuff ends */

/* Home stuff beings */

.intro-text { 
  font-family: 'Alegreya', serif;
  font-size: 2.8em;
  margin-top: 0.8em;
  text-align: center;
  margin-bottom: 0;
}

.tldr { 
  font-weight: bold;
}

.tldr-text { 
  font-size: 1.3em;
  padding: 0px 0px 10px 0px;
}

.portrait-img { 
  margin-right: 6em;
  height: 400px;
}

.home-links { 
  list-style-type: none;
  overflow: hidden;
  padding-left: 20px;
  text-align: center;
}

.home-links li { 
  margin: 0.6em;
  font-size: larger;
  font-weight: bold;
}

.home-links a:link { 
  color: #A14112;
  font-weight: bold;
  text-decoration: none;
}
.home-links a:visited { 
  color: #A14112;
  font-weight: bold;
  text-decoration: none;
}
.home-links a:hover { 
  color: #B6465F;
  font-weight: bold;
  text-decoration: underline;
}
.home-links a:active { 
  color: #A14112;
  font-weight: bold;
  text-decoration: underline;
}

.links-container { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

/* Home stuff ends */

/* About stuff begins */

.about-text-container{ 
  padding: 0px 0px 10px 0px;
}

.about-text { 
  font-size: 1.2em;
}

/* About stuff ends */

/* Footer stuff begins */

.footer-container { 
  margin-top: 2em;
  margin-bottom: 1.5em;
}

.copyright { 
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: 1em;
}

.copyright p { 
  margin-top: -3px;
  margin-left: 6px;
  margin-right: 2em;
  color: gray;
}

.footer-icon { 
  margin-right: 0.4em;
}

/* Blog stuff begins */

.blog-list {
  padding: 0;
}

.blog-card { 
  background-color: transparent !important;
  border: 3px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 8px !important;
}

.blog-card h2 { 
  font-family: 'Open Sans', sans-serif;
}

.blog-card p { 
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
}

.blog-card-container { 
  margin: 10px;
}

.blog-post-container { 
  padding: 30px;
}

.blog-post-container p{ 
  font-size: 20px;
  line-height: 2;
  width: 90%;
}

.blog-post-container li{ 
  font-size: 18px;
}

.blog-post-container strong{ 
  color: darkmagenta;
}

.blog-post-container em{ 
  color: darkslateblue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: smaller;
}

.blog-post-container a{ 
  color: darkred;
  background-color: rgba(255, 0, 0, 0.18);
}

.blog-post-container img { 
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2em;
  width: 70%;
}
/* Blog stuff ends */

/* Project stuff begins */

.proj-list {
  padding: 0;
}

.proj-card { 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent !important;
  border: 3px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 8px !important;
  width: 47%;
  max-height: 320px;
}

.proj-card h2 { 
  font-family: 'Merriweather', serif;
}

.proj-card p { 
  margin-top: 10px;
  font-size: 16px;
  font-family: 'Crimson Text', serif;
}

.project-card-container { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.project-card-image-container { 
  background-color: transparent;
  background: transparent;
  position: relative;
  opacity: 1;
  overflow:hidden;
}

.project-card-image-container:hover { 
  opacity: 0.8;
  color: black;
  background-color: black;
  background: black;
}

.project-info-container p{ 
  font-size: 20px;
}

.project-info-container li{ 
  font-size: 18px;
}

.project-info-container strong{ 
  color: darkmagenta;
}

.project-info-container em{ 
  color: darkslateblue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: smaller;
}

.project-info-container a{ 
  color: darkred;
  background-color: rgba(255, 0, 0, 0.18);
}

.project-info-container img { 
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2em;
  width: 70%;
}

/* Project stuff end */

 /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
 .flip-card {
  background-color: transparent;
  width: 50px;
  height: 50px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
} 

.navbar-logo-image { 
  height: 50px;
  width: 50px;
}

.name-logo-flip-cards { 
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
  margin-top: 1em;
}



/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width: 728px) {
  .website-container { 
    max-width: 80%;
    margin: auto;
  }
}

/* MEDIA QUERY FOR PHONES */
@media only screen and (max-width: 600px) {
  .website-container { 
    max-width: 90%;
    margin: auto;
  }

  .intro-text { 
    text-align: center;
  }

  .tldr-text { 
    text-align: center;
    font-size: 1.2em;
  }

  .portrait-container { 
    flex-direction: column-reverse;
  }

  .portrait-img { 
    scale: 80%;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .links-container { 
    margin-bottom: 0;
  }

  .home-links { 
    padding-left: 0;
  }

  .navbar-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navmenu { 
    padding: 0;
    margin-top: 10px;
    padding-bottom: 1em;
  }

  .proj-list {
    margin-top: 0;
  }

  .project-card-container { 
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .proj-card { 
    width: 100%;
    margin-top: 20px;
  }

  .section-header { 
    font-size: 1.5em;
    font-family: 'Alegreya', serif;
  }

  .footer-icon { 
    margin-right: 0.4em;
  }

  .copyright p { 
    margin-top: -3px;
    margin-left: 6px;
    font-size: 14px;
    margin-right: 1em;
    color: gray;
  }

  .blog-post-container { 
    padding: 10px;
  }
  
  .blog-post-container p{ 
    font-size: 16px;
    line-height: 2;
    width: 90%;
  }

  .blog-post-container img{ 
    width: 100%;
  }
  
  .project-info-container p{ 
    font-size: 16px;
  }

  .project-info-container li{ 
    font-size: 16px;
  }

  .project-info-container img{ 
    width: 100%;
  }
}
